/* eslint-disable */
import PropTypes from "prop-types";
import IMAGES from "../../assets/images";
import Slider from "react-slick";
import { Card, message } from "antd";
import { useState } from "react";
import { addToCart, openCartAction } from "../../app/features/cart/cart.slice";
import { useDispatch, useSelector } from "react-redux";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      role="presentation"
      className="arrow-right-parent py-2"
    >
      <div className="d-md-block d-none">
        <img src={IMAGES.RIGHT_ARROW} className="right-arrow" />
      </div>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      role="presentation"
      className="arrow-left-parent py-2"
    >
      <div className="d-md-block d-none">
        <img src={IMAGES.LEFT_ARROW} className="left-arrow" />
      </div>
    </div>
  );
}

function ProductCard(props) {
  const { product, id, handleClick } = props;
  const [selectedSize, setSelectedSize] = useState();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => <div className="bottom-bars">{dots}</div>,
    customPaging: (i) => <div className="bottom-bars" />,
  };
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const handleAddToCart = (product) => {
    if (!selectedSize) {
      message.error(`please select a size first`);
      return;
    }
    const foundProduct = cart?.cartItems?.find((product) => product.id === id);

    if (
      !foundProduct ||
      !foundProduct?.cartQuantity?.find((item) => item.size === selectedSize)
        ?.quantity
    ) {
      const finalData = {
        ...product,
        id,
        selectedSize,
      };
      dispatch(addToCart(finalData));
      dispatch(openCartAction());
    } else {
      if (
        foundProduct?.cartQuantity?.find((item) => item.size === selectedSize)
          ?.quantity < product.quantityAvailable[selectedSize]
      ) {
        const finalData = {
          ...product,
          id,
          selectedSize,
        };
        dispatch(addToCart(finalData));
        dispatch(openCartAction());
      } else {
        message.error(`${product.title} is not available in selected size`);
      }
    }
  };

  return (
    <Card className="glassy-card">
      <div className="d-flex align-items-start justify-content-between px-2">
        <div className="fs-xs fw-normal pt-1 pb-2 text-black truncate">
          {`T Shirt - ${product?.title} - $${product?.price}`}
        </div>
        <div className="d-flex align-items-center">
          <div className="window-icons bblr">
            <img
              width="14px"
              className="cursor-pointer window-inner-icon"
              src={IMAGES.MIN_ICON}
              alt="window icons"
            />
          </div>
          <div className="window-icons">
            <img
              width="12px"
              className="cursor-pointer window-inner-icon"
              src={IMAGES.MAX_ICON}
              alt="window icons"
            />
          </div>
          <div className="window-icons br-1 bbrr">
            <img
              width="12px"
              className="cursor-pointer window-inner-icon"
              src={IMAGES.CLOSE_ICON}
              alt="window icons"
            />
          </div>
        </div>
      </div>
      <div className="glassy-card-inner" onClick={() => handleClick(id)}>
        <Slider {...settings} className="mt-2 product-carousel">
          {product?.images?.map((item) => (
            <img
              className="pt-1 pb-3 product-img"
              src={item.url}
              alt="carousel"
            />
          ))}
        </Slider>
      </div>
      <div className="p-2 py-3 d-flex align-items-center justify-content-center">
        {/* <div className="text-black sizes mr-2">S</div> */}
        <div
          className={`${
            product?.quantityAvailable?.sm > 0 && selectedSize === "sm"
              ? "active-size-btn"
              : ""
          } text-black sizes mr-2 cursor-pointer ${
            product?.quantityAvailable?.sm > 0 ? "" : "disabled"
          }`}
          onClick={() => setSelectedSize("sm")}
        >
          S
        </div>
        <div
          className={`${
            product?.quantityAvailable?.md > 0 && selectedSize === "md"
              ? "active-size-btn"
              : ""
          } text-black sizes mr-2 cursor-pointer ${
            product?.quantityAvailable?.md > 0 ? "" : "disabled"
          }`}
          onClick={() => setSelectedSize("md")}
        >
          M
        </div>
        <div
          className={`${
            product?.quantityAvailable?.lg > 0 && selectedSize === "lg"
              ? "active-size-btn"
              : ""
          } text-black sizes mr-2 cursor-pointer ${
            product?.quantityAvailable?.lg > 0 ? "" : "disabled"
          }`}
          onClick={() => setSelectedSize("lg")}
        >
          L
        </div>
        <div
          className={`${
            product?.quantityAvailable?.xl > 0 && selectedSize === "xl"
              ? "active-size-btn"
              : ""
          } text-black sizes mr-2 mr-sm-4 cursor-pointer ${
            product?.quantityAvailable?.xl > 0 ? "" : "disabled"
          }`}
          onClick={() => setSelectedSize("xl")}
        >
          XL
        </div>
        <div
          role="presentation"
          className="text-black add-to-cart-btn"
          onClick={() => handleAddToCart(product)}
        >
          Add to cart
        </div>
      </div>
    </Card>
  );
}

export default ProductCard;

ProductCard.propTypes = {
  product: PropTypes.node.isRequired,
  id: PropTypes.node.isRequired,
  handleClick: PropTypes.node.isRequired,
};
