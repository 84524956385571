/* eslint-disable */
import PropTypes from "prop-types";
import { Button, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import PATH from "../../utils/path";
import { loadStripe } from "@stripe/stripe-js";
import {
  closeCartAction,
  decreaseCart,
  getTotals,
  increaseCart,
  removeFromCart,
  // toggleCartOpen,
} from "../../app/features/cart/cart.slice";
import { Loader1 } from "../../components/Layout/Loader/FullScreenLoader";
import { createOrderAction } from "../../app/features/Order/Order.slice";
import IMAGES from "../../assets/images";
import Footer from "./Footer";

function IncreaseCartButton({ product }) {
  const dispatch = useDispatch();

  const handleIncreaseCart = () => {
    if (
      product?.cartQuantity?.find((item) => item.size === product.selectedSize)
        ?.quantity < product.quantityAvailable[product.selectedSize]
    ) {
      dispatch(increaseCart(product));
    } else {
      message.error(`${product.title} is not available in selected size`);
    }
  };

  return (
    <AiOutlinePlus
      size={12}
      style={{ marginRight: "0.6rem" }}
      className="cursor-pointer"
      onClick={handleIncreaseCart}
    />
  );
}

function DecreaseCartButton({ product }) {
  const dispatch = useDispatch();

  const handleDecreaseCart = () => {
    dispatch(decreaseCart(product));
  };

  return (
    <AiOutlineMinus
      size={12}
      style={{ marginRight: "0.6rem", marginLeft: "0.6rem" }}
      className="cursor-pointer"
      onClick={handleDecreaseCart}
    />
  );
}

function RemoveFromCartButton({ product }) {
  const dispatch = useDispatch();

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart(product));
  };

  return (
    <div
      className="text-danger d-flex cursor-pointer"
      role="presentation"
      onClick={handleRemoveFromCart}
    >
      Remove
    </div>
  );
}

function PublicLayout({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [cartOpen, setCartOpen] = useState(false);
  // const navigate = useNavigate();

  const showCartDrawer = () => {
    setCartOpen(true);
  };
  const onCartClose = () => {
    setCartOpen(false);
  };

  const cart = useSelector((state) => state.cart);
  console.log("cart", cart);
  const { isLoading } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
  );

  const moveToNext = async (response) => {
    const stripe = await stripePromise;
    // const session = await stripe.checkout.sessions.retrieve(response.id);
    await stripe.redirectToCheckout({ sessionId: response.id });
  };

  const handlePayment = async () => {
    const finalData = {
      data: {
        products: cart?.cartItems,
      },
    };
    dispatch(createOrderAction({ finalData, moveToNext }));
  };

  useEffect(() => {
    if (cart?.isCartOpen) {
      showCartDrawer();
    }
  }, [cart]);

  useEffect(() => {
    onCartClose();
  }, [location.pathname]);

  return (
    <div className="home-parent vh-100 main-bg-image align-items-center">
      <img className="water-mark" src={IMAGES.HEADER} alt="header" />
      <div className="icon-container">
        <img className="icon" src={IMAGES.BANDICAM} alt="bg icons" />
        <img className="icon" src={IMAGES.FOLDER_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.IE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.LIVE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.MAHJONG_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.MAPLE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.RS_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.SKYPE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.MINE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.TUMBLR_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.WINRAR_ICON} alt="bg icons" />
      </div>
      <div className="cart-container">
        <div
          className="cart-parent cursor-pointer"
          role="presentation"
          onClick={showCartDrawer}
        >
          <img src={IMAGES.CART} alt="cart icon" className="cart-icon" />
          <div className="cart-count">{cart.cartItems?.length}</div>
        </div>
        <div className="gallery-container">
          <img src={IMAGES.CAT} alt="cart icon" className="cat-img" />
          <img
            src={IMAGES.GALLERY_WIDGET}
            alt="cart icon"
            className="gallery-widget"
          />
        </div>
        <div className="player-container d-none d-sm-flex">
          <video className="sample-video" autoPlay loop muted>
            <source src={IMAGES.SAMPLE_VIDEO} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <img
            src={IMAGES.VIDEO_WIDGET}
            alt="cart icon"
            className="video-widget"
          />
        </div>
        <img
          src={IMAGES.MP3_WIDGET}
          alt="cart icon"
          className="mp3-widget pb-5"
        />
      </div>
      <div className="container d-flex justify-content-between align-items-center">
        <div className={`custom-right-drawer ${cartOpen ? "d-block" : ""}`}>
          <div
            className="variable-scroll"
            style={{ height: "calc(100vh - 180px)" }}
          >
            <div className="d-flex align-items-start justify-content-end mb-2">
              <div
                className="text-danger cursor-pointer"
                onClick={onCartClose}
                role="presentation"
              >
                <img
                  src={IMAGES.CART_CLOSE_ICON}
                  alt="cart close icon"
                  width="50px"
                  className="cross-icon"
                />
              </div>
            </div>
            {cart?.cartItems.map((item) => (
              <div className="cart-item-wrapper p-2 px-3 mb-2 d-flex align-items-center">
                <img
                  src={item?.images[0]?.url}
                  alt="product img"
                  width={55}
                  className="mr-3"
                />
                <div className="w-100">
                  <div className="d-flex align-items-end justify-content-between">
                    <div className="product-title">{item?.title}</div>
                    <div className="product-price">{`$${item?.price}`}</div>
                  </div>
                  {item?.cartQuantity.map((qty) => (
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="text-gray  fs-xxs d-flex align-items-center mr-3">
                          {/* <IncreaseCartButton
                          product={{ ...item, selectedSize: qty?.size }}
                          cart={cart}
                        /> */}
                          Qt: {qty?.quantity}
                          {/* <DecreaseCartButton
                          product={{ ...item, selectedSize: qty?.size }}
                        /> */}
                        </div>
                        <div className="text-gray fs-xxs  ">
                          Size: {qty?.size}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <RemoveFromCartButton product={item} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="w-100 d-flex justify-content-between">
              <p className="m-0 fs-md fw-normal text-black">Subtotal:</p>
              <p className="m-0 fs-md fw-normal text-black">
                {`$${cart?.cartTotalAmount}`}
              </p>
            </div>
            <Button
              className="text-black w-100 checkout-btn fs-sm fw-normal text-black"
              onClick={() => handlePayment()}
            >
              Checkout
            </Button>
          </div>
        </div>
      </div>
      <div className="container scroll-within">{children}</div>
      <Footer />
      {/* <img src={IMAGES.FOOTER} alt="footer" className="footer" /> */}
    </div>
  );
}
PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default PublicLayout;
