import PropTypes from "prop-types";
// import Header from "./AdminHeader";
import AdminFooter from "./AdminFooter";
import IMAGES from "../../../assets/images";

function PublicLayout({ children }) {
  return (
    <div className="home-parent signing-input-fields vh-100 main-bg-image align-items-center pages-component">
      <img className="water-mark" src={IMAGES.HEADER} alt="header" />
      <div className="icon-container">
        <img className="icon" src={IMAGES.BANDICAM} alt="bg icons" />
        <img className="icon" src={IMAGES.FOLDER_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.IE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.LIVE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.MAHJONG_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.MAPLE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.RS_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.SKYPE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.MINE_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.TUMBLR_ICON} alt="bg icons" />
        <img className="icon" src={IMAGES.WINRAR_ICON} alt="bg icons" />
      </div>
      {/* <Header /> */}
      <div className="container scroll-within pt-5">{children}</div>
      <AdminFooter />
    </div>
  );
}
PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default PublicLayout;
