/* eslint-disable */
import PropTypes from "prop-types";
import { Card, Col, Row, Modal } from "antd";
import IMAGES from "../../assets/images";

function FaqModal(props) {
  const { open, setOpen } = props;
  return (
    <Modal
      // centered
      open={open}
      //   onOk={() => setOpen(false)}
      //   onCancel={() => setOpen(false)}
      width={700}
      closable={false}
      footer={null}
      className="faq-modal"
      //   maskClosable
    >
      <Card className="glassy-card">
        <div
          className="d-flex align-items-start justify-content-between"
          style={{ padding: "0 0.5rem" }}
        >
          <div className="fs-xs fw-normal py-1 mb-1 text-black">
            Frequently asked questions
          </div>
          <img
            src={IMAGES.CART_CLOSE_ICON}
            alt="cart close icon"
            width="50px"
            onClick={() => setOpen(false)}
            className="cursor-pointer cross-icon"
          />
        </div>
        <div
          className="bg-white p-2 mb-2 faq font-lucida"
          style={{ minHeight: "50vh" }}
        >
          <h2 className="fw-normal">
            hi guys!!!1!1
            <br />
            Answers for frequently asked questions:
          </h2>
          <h4 className="fw-normal">
            -This is one man operation, everything is hand printed by me
            <br />
            -All orders guaranteed to ship within 2-5 days of the order date
            <br />
            -All tees are printed on Hanes Beefy-t{" "}
            <span className="text-blue underline">size chart</span>
          </h4>
        </div>
      </Card>
    </Modal>
  );
}

FaqModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
export default FaqModal;
