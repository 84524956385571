import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LoginServices from "./auth.service";

const initialState = {
  login: localStorage.getItem("uh2_user")
    ? JSON.parse(localStorage.getItem("uh2_user"))
    : [],
  isError: false,
  loginisSuccess: false,
  isLoading: false,
  message: "",
};

export const loginAction = createAsyncThunk(
  "login/loginAction",
  async ({ user, setLocalStorage, Notification }, thunkAPI) => {
    try {
      const response = await LoginServices.login(user, setLocalStorage);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(error?.response?.data?.error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const logoutAction = createAsyncThunk("auth/logout", async () => {
  await LoginServices.logout();
});

export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearLogin: (state) => {
      state.login = [];
      state.isLoading = false;
      state.loginisSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loginisSuccess = true;
        state.login = action.payload;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state.login = null;
      });
  },
});

export const { clearLogin } = LoginSlice.actions;

export default LoginSlice.reducer;
