import { useState } from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../assets/images";
import PATH from "../../utils/path";
import ContactModal from "../../pages/components/ContactModal";
import FaqModal from "../../pages/components/FaqModal";

function Footer() {
  const [open, setOpen] = useState(false);
  const [faqOpen, setFaqOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="footer">
      <Row className="w-100 px-md-3 px-2 d-flex justify-content-between align-items-center">
        <Col
          flex="auto"
          // xs={14}
          md={16}
          lg={14}
          className="d-flex align-items-center"
        >
          <img
            src={IMAGES.WINDOW_LOGO}
            alt="window logo"
            className="window-logo mr-2 mr-md-3"
          />
          <img
            src={IMAGES.BROWSER_ICON}
            alt="window logo"
            height={18}
            className="mr-3 d-none d-md-block"
          />
          <img
            src={IMAGES.NOTE_PAD}
            alt="window logo"
            height={18}
            className="mr-2 mr-md-3 btn-icon"
          />
          <img
            src={IMAGES.FE_ICON}
            alt="window logo"
            height={18}
            className="mr-2 mr-md-3 btn-icon"
          />
          <div
            className="align-items-center home-btn mr-2 text-white cursor-pointer pr-2 fs-xxs d-none d-md-flex"
            role="presentation"
            onClick={() => navigate(PATH.HOME)}
          >
            <img
              src={IMAGES.VISTA_HOME_ICON}
              alt="window logo"
              height={18}
              className="btn-icon px-md-2 px-1"
            />
            Home
          </div>
          <div
            className="d-flex align-items-center home-btn mr-1 mr-md-2 text-white cursor-pointer pr-2 fs-xxs"
            role="presentation"
            // onClick={() => navigate(PATH.CONTACT)}
            onClick={() => setOpen(true)}
          >
            <img
              src={IMAGES.VISTA_CONTACT_ICON}
              alt="window logo"
              height={18}
              className="btn-icon px-md-2 px-1"
            />
            Contact
          </div>
          <div
            className="d-flex align-items-center home-btn mr-1 mr-md-2 text-white cursor-pointer pr-2 fs-xxs"
            role="presentation"
            // onClick={() => navigate(PATH.FAQ)}
            onClick={() => setFaqOpen(true)}
          >
            <img
              src={IMAGES.VISTA_CONTACT_ICON}
              alt="window logo"
              height={18}
              className="btn-icon px-md-2 px-1"
            />
            F.A.Q
          </div>
        </Col>
        <Col
          flex="none"
          className="justify-content-end align-items-center d-flex time-sm text-white"
        >
          <img
            src={IMAGES.DOWNLOAD_ICON}
            alt="window logo"
            height={18}
            className="mr-3  d-none d-md-flex"
          />
          <img
            src={IMAGES.GAME_ICON}
            alt="window logo"
            height={18}
            className="mr-2 mr-md-3 btn-icon"
          />
          <img
            src={IMAGES.NETWORK_ICON}
            alt="window logo"
            height={18}
            className="mr-3 d-none d-md-flex"
          />
          <span style={{ whiteSpace: "nowrap" }}>2:20 AM</span>
        </Col>
      </Row>
      <ContactModal open={open} setOpen={setOpen} />
      <FaqModal open={faqOpen} setOpen={setFaqOpen} />
    </div>
  );
}

export default Footer;
