/* eslint-disable */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  // ,Card
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import PATH from "../utils/path.js";
import { getAllProductsDetailAction } from "../app/features/ProductsDetail/ProductsDetail.slice.js";
import ProductCard from "./components/ProductCard.jsx";


function Index() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Welcome to uh2";
  });

  // const handleClick = (id) => {
  //   navigate(PATH.PRODUCT.replace(":id", id));
  // };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsDetailAction());
  }, [dispatch]);

  const { getAllProductsDetail } = useSelector((state) => state.productsDetail);

  return (
    <Row gutter={24} className="all-products-wrapper">
      {getAllProductsDetail?.data?.map((item) => (
        <Col
          key={item.id}
          xs={24}
          md={12}
          lg={8}
          xl={8}
          className="cursor-pointer px-3 pb-3"
          role="presentation"
          // onClick={() => handleClick(item.id)}
        >
          <ProductCard
            // handleClick={handleClick}
            product={item?.attributes}
            id={item?.id}
          />
        </Col>
      ))}
    </Row>
  );
}
export default Index;
