import { useEffect, useState } from "react";
import { Button, Col, Row, Table, Tag, message } from "antd";
// import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
// import IMAGES from "../../assets/images";
// import PATH from "../../utils/path";
import {
  deleteProductAction,
  getAllProductsDetailAction,
} from "../../app/features/ProductsDetail/ProductsDetail.slice";
import AddProductModal from "./AddUpdateProduct";
import UpdateProductModal from "./UpdateProduct";

function Index() {
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  function Notification(type) {
    if (type === "success") {
      message.success("product deleted successfully!");
    } else {
      message.error("failed to delete product!");
    }
  }

  const deleteProduct = (record) => {
    const productId = record.id;
    dispatch(deleteProductAction({ productId, Notification }));
  };
  const columns = [
    {
      dataIndex: "url",
      key: "url",
      align: "center",
      title: "Product",
      render: (imgSrc) => (
        <img className="thumbnail" src={imgSrc} alt="thumbnail" />
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: "About",
      dataIndex: "about",
      key: "about",
      // width: "600px",
      render: (about) => <span className="truncate">{about}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (price) => (price ? `$${price}` : `$${0}`),
    },
    {
      title: "Sizes",
      key: "quantityAvailable",
      dataIndex: "quantityAvailable",
      align: "center",
      width: "200px",
      render: (sizes) =>
        sizes
          ? Object.keys(sizes)?.map((size) => (
              <Tag color="gold" className="m-1">
                {size}
              </Tag>
            ))
          : "",
    },
    {
      title: "Quantity",
      key: "quantityAvailable",
      dataIndex: "quantityAvailable",
      align: "center",
      render: (quantity) =>
        quantity
          ? Object.values(quantity).reduce((a, b) => Number(a) + Number(b), 0)
          : 0,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <div size="middle">
          <FiEdit
            size={20}
            className="mr-3 cursor-pointer"
            onClick={() =>
              // navigate(PATH.ADMIN_UPDATE_PRODUCT.replace(":id", record.id))
              {
                setSelectedProductId(record.id);
                setUpdateOpen(true);
              }
            }
          />
          <AiOutlineDelete
            className="cursor-pointer"
            size={20}
            onClick={() => deleteProduct(record)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllProductsDetailAction());
  }, [dispatch, open, updateOpen]);

  const { getAllProductsDetail } = useSelector((state) => state.productsDetail);

  const productsData = getAllProductsDetail?.data?.map((product) => {
    const url = product?.attributes?.images[0]?.url;
    return { ...product.attributes, url, id: product.id };
  });

  return (
    <>
      {" "}
      <Row className="w-100 d-flex justify-content-end mb-3">
        {/* <Col>
          <Input placeholder="Search" />
        </Col> */}
        <Col>
          <Button className="add-btn" onClick={() => setOpen(true)}>
            Add Products
          </Button>
        </Col>
      </Row>
      {productsData && (
        <Table
          scroll={{
            y: "calc(100vh - 320px)",
            x: "1000px",
          }}
          pagination={{
            pageSize: 10,
          }}
          columns={columns}
          dataSource={productsData}
        />
      )}
      <AddProductModal open={open} setOpen={setOpen} />
      <UpdateProductModal
        open={updateOpen}
        setOpen={setUpdateOpen}
        id={selectedProductId}
      />
    </>
  );
}

export default Index;
