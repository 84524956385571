import api from "../../../utils/api";

const login = async (userData, setLocalStorage) => {
  const response = await api.post(`api/auth/local`, userData);

  if (response.data) {
    const user = response.data;

    api.defaults.headers.Authorization = `Bearer ${user.jwt}`;

    // delete user.moduleList;
    setLocalStorage(user);
  }
  return response.data;
};

const logout = async () => {
  localStorage.removeItem("uh2_user");
  return "user logout successfully";
};

const LoginServices = {
  login,
  logout,
};

export default LoginServices;
