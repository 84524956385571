/* eslint-disable */
import { useEffect } from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getProductsDetailByIdAction } from "../app/features/ProductsDetail/ProductsDetail.slice";
import { getTotals } from "../app/features/cart/cart.slice";
import ProductCard from "./components/ProductCard";

function Product() {
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();

  const { getProductsDetailById } = useSelector(
    (state) => state.productsDetail
  );

  console.log("getProductsDetailById", getProductsDetailById);
  useEffect(() => {
    dispatch(getProductsDetailByIdAction(id));
    dispatch(getTotals());
  }, [dispatch, id]);

  return (
    <Row
      gutter={24}
      className="all-products-wrapper d-flex justify-content-center"
    >
      <Col
        key={getProductsDetailById?.id}
        xs={24}
        md={12}
        lg={8}
        xl={8}
        className="cursor-pointer px-3 pb-3"
      >
        <ProductCard
          product={getProductsDetailById?.attributes}
          className="cursor-pointer"
          id={getProductsDetailById?.id}
          onClick={() => handleClick(getProductsDetailById?.id)}
        />
      </Col>
    </Row>
  );
}

export default Product;
