import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Upload,
  message,
  Card,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addProductsDetailAction,
  uploadImagesAction,
} from "../../app/features/ProductsDetail/ProductsDetail.slice";
import PATH from "../../utils/path";
import { Loader1 } from "../../components/Layout/Loader/FullScreenLoader";
import IMAGES from "../../assets/images";

const { TextArea } = Input;

function AddProductModal(props) {
  const { open, setOpen } = props;
  const [allUploadedImages, setAllUploadedImages] = useState([]);
  const { uploadImages, isUploading, isLoading } = useSelector(
    (state) => state.productsDetail
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function moveToNext() {
    navigate(PATH.ADMIN_ALL_PRODUCTS);
  }

  function Notification(notification, type) {
    if (type === "success") {
      message.success(notification);
    } else {
      message.error(notification);
    }
  }

  const onFinish = async (values) => {
    const images = allUploadedImages.map((item) => ({
      id: item.id,
      url: item.url,
    }));
    if (images.length !== 0) {
      const finalData = {
        data: {
          title: values.title,
          about: values.about,
          price: values.price,
          images,
          quantityAvailable: {
            sm: values.smallQty,
            md: values.mediumQty,
            lg: values.largeQty,
            xl: values.xlQty,
          },
        },
      };
      dispatch(
        addProductsDetailAction({ finalData, Notification, moveToNext })
      );
    } else {
      message.error("Please select an image");
    }
  };

  const [fileList, setFileList] = useState([]);
  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const ImageUploadNotification = (type) => {
    if (type === "error") {
      message.error("failed to upload image successfully");
    } else {
      message.success("Image uploaded successfully!");
    }
  };
  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      dispatch(uploadImagesAction({ formData, ImageUploadNotification }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 100);
  };

  useEffect(() => {
    if (uploadImages.length !== 0) {
      setAllUploadedImages((prev) => [...prev, uploadImages[0]]);
    }
  }, [uploadImages]);

  return (
    <Modal
      // centered
      // maskClosable
      open={open}
      destroyOnClose
      //   onOk={() => setOpen(false)}
      //   onCancel={() => setOpen(false)}
      width={700}
      closable={false}
      footer={null}
      style={{
        top: 40,
      }}
    >
          <Card className="glassy-card mb-0">
            <div
              className="d-flex align-items-start justify-content-between"
              style={{ padding: "0 0.5rem" }}
            >
              <div className="fs-xs fw-normal py-1 text-black">Add Product</div>
              <img
              role="presentation"
                src={IMAGES.CART_CLOSE_ICON}
                alt="cart close icon"
                width="50px"
                onClick={() => setOpen(false)}
                className="cursor-pointer cross-icon"
              />
            </div>
            <div className="bg-white p-3 mb-2">
              <Form
                layout="vertical"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                requiredMark={false}
              >
                <Form.Item
                  label={<div className="text-black">title</div>}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter title!",
                    },
                  ]}
                >
                  <Input placeholder="enter product title" />
                </Form.Item>

                <Form.Item
                  label={<div className="text-black">about</div>}
                  name="about"
                  rules={[
                    {
                      //   required: true,
                      message: "Please enter about!",
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="brief product description" />
                </Form.Item>

                <Form.Item
                  className="w-100"
                  label={<div className="text-black">price</div>}
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: "Please enter price!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="enter price" />
                </Form.Item>

                <Row gutter={12} className="mb-3">
                  <Col className="text-black mb-1" xs={24}>
                    Quantity
                  </Col>
                  <Col xs={24} sm={6} className="mb-1">
                    <Form.Item
                      className="mb-1"
                      // label={<div className="text-black">small</div>}
                      name="smallQty"
                      initialValue="0"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input small qty!",
                      //   },
                      // ]}
                    >
                      <Input type="number" placeholder="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} className="mb-1">
                    <Form.Item
                      className="mb-1"
                      name="mediumQty"
                      initialValue="0"
                    >
                      <Input type="number" placeholder="medium" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} className="mb-1">
                    <Form.Item
                      className="mb-1"
                      name="largeQty"
                      initialValue="0"
                    >
                      <Input type="number" placeholder="large" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} className="mb-1">
                    <Form.Item className="mb-1" name="xlQty" initialValue="0">
                      <Input type="number" placeholder="extra large" />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="text-black mb-2">product images</div>
                <Upload
                  customRequest={dummyRequest}
                  multiple
                  listType="picture-card"
                  disabled={isUploading || isLoading}
                  name="files"
                  fileList={fileList}
                  onChange={onChange}
                  beforeUpload={handleImageUpload}
                  onPreview={onPreview}
                >
                  {!isUploading ? (
                    fileList.length < 10 && "+ Upload"
                  ) : (
                    <Loader1 />
                  )}
                </Upload>
                <Row gutter={8} className="w-100 d-flex justify-content-end">
                  <Col className="mt-2 mr-2">
                    <Button
                    onClick={()=>setOpen(false)}
                      className="submit-btn-solid"
                      disabled={isUploading || isLoading}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="mt-2 submit-btn-solid"
                        htmlType="submit"
                        disabled={isUploading || isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
    </Modal>
  );
}

export default AddProductModal;
AddProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
