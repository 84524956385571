import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Upload,
  message,
  Card,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  getProductsDetailByIdAction,
  updateProductsDetailAction,
  uploadImagesAction,
} from "../../app/features/ProductsDetail/ProductsDetail.slice";
import { Loader1 } from "../../components/Layout/Loader/FullScreenLoader";
import IMAGES from "../../assets/images";

const { TextArea } = Input;

function UpdateProductModal(props) {
  const { open, setOpen, id } = props;
  // const { id } = useParams();
  const [allUploadedImages, setAllUploadedImages] = useState([]);

  const {
    uploadImages,
    getProductsDetailById,
    isUploading,
    isUpdating,
    isLoading,
  } = useSelector((state) => state.productsDetail);

  const dispatch = useDispatch();

  function moveToNext() {
    setOpen(false);
  }

  function Notification(notification, type) {
    if (type === "success") {
      message.success(notification);
    } else {
      message.error(notification);
    }
  }

  const onFinish = async (values) => {
    const images = allUploadedImages.map((item) => ({
      url: item.url,
    }));
    if (images.length !== 0) {
      const finalData = {
        data: {
          title: values.title,
          about: values.about,
          price: values.price,
          images,
          quantityAvailable: {
            sm: values.smallQty,
            md: values.mediumQty,
            lg: values.largeQty,
            xl: values.xlQty,
          },
        },
      };
      dispatch(
        updateProductsDetailAction({
          finalData,
          productId: id,
          Notification,
          moveToNext,
        })
      );
    } else {
      message.error("Please select an image");
    }
  };

  const removeImage = (file) => {
    setAllUploadedImages((prev) => prev.filter((img) => img.id !== file.id));
  };
  const onPreview = (file) => {
    const src = file.url;
    if (src) {
      window.open(src, "_blank", "noreferrer");
    }
  };

  const ImageUploadNotification = (type) => {
    if (type === "error") {
      message.error("failed to upload image successfully");
    } else {
      message.success("Image uploaded successfully!");
    }
  };
  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      dispatch(uploadImagesAction({ formData, ImageUploadNotification }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 100);
  };

  useEffect(() => {
    if (uploadImages.length !== 0) {
      setAllUploadedImages((prev) => [...prev, uploadImages[0]]);
    }
  }, [uploadImages]);

  useEffect(() => {
    dispatch(getProductsDetailByIdAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!isLoading && getProductsDetailById.length !== 0) {
      const modiedImageList = getProductsDetailById?.attributes?.images?.map(
        (item) => ({
          id: item.id,
          url: item.url,
          status: "done",
        })
      );

      setAllUploadedImages(modiedImageList);
    }
  }, [getProductsDetailById]);

  return (
    <Modal
      // centered
      // maskClosable
      open={open}
      destroyOnClose
      //   onOk={() => setOpen(false)}
      //   onCancel={() => setOpen(false)}
      width={700}
      closable={false}
      footer={null}
      style={{
        top: 40,
      }}
    >
      <Card className="glassy-card mb-0">
        <div
          className="d-flex align-items-start justify-content-between"
          style={{ padding: "0 0.5rem" }}
        >
          <div className="fs-xs fw-normal py-1 text-black">Update Product</div>
          <img
            src={IMAGES.CART_CLOSE_ICON}
            alt="cart close icon"
            role="presentation"
            width="50px"
            onClick={() => setOpen(false)}
            className="cursor-pointer cross-icon"
          />
        </div>
        <div className="bg-white p-3 mb-2">
          {!isLoading ? (
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              requiredMark={false}
            >
              <Form.Item
                label={<div className="text-black">title</div>}
                name="title"
                initialValue={getProductsDetailById?.attributes?.title || ""}
                rules={[
                  {
                    required: true,
                    message: "Please enter title!",
                  },
                ]}
              >
                <Input placeholder="enter product title" />
              </Form.Item>

              <Form.Item
                label={<div className="text-black">about</div>}
                name="about"
                initialValue={getProductsDetailById?.attributes?.about || ""}
                rules={[
                  {
                    required: true,
                    message: "Please enter about!",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="brief product description" />
              </Form.Item>

              <Form.Item
                className="w-100"
                label={<div className="text-black">price</div>}
                name="price"
                initialValue={getProductsDetailById?.attributes?.price || ""}
                rules={[
                  {
                    required: true,
                    message: "Please enter price!",
                  },
                ]}
              >
                <Input type="number" placeholder="enter price" />
              </Form.Item>
              <Row gutter={12} className="mb-3">
                <Col className="text-black mb-1" xs={24}>
                  Quantity
                </Col>
                <Col xs={24} sm={6} className="mb-1">
                  <Form.Item
                    className="mb-1"
                    name="smallQty"
                    initialValue={
                      getProductsDetailById?.attributes?.quantityAvailable
                        ?.sm || "0"
                    }
                  >
                    <Input type="number" placeholder="small" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} className="mb-1">
                  <Form.Item
                    className="mb-1"
                    name="mediumQty"
                    initialValue={
                      getProductsDetailById?.attributes?.quantityAvailable
                        ?.md || "0"
                    }
                  >
                    <Input type="number" placeholder="medium" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} className="mb-1">
                  <Form.Item
                    className="mb-1"
                    name="largeQty"
                    initialValue={
                      getProductsDetailById?.attributes?.quantityAvailable
                        ?.lg || "0"
                    }
                  >
                    <Input type="number" placeholder="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} className="mb-1">
                  <Form.Item
                    className="mb-1"
                    name="xlQty"
                    initialValue={
                      getProductsDetailById?.attributes?.quantityAvailable
                        ?.xl || "0"
                    }
                  >
                    <Input type="number" placeholder="extra large" />
                  </Form.Item>
                </Col>
              </Row>

              <div className="text-black mb-2">product images</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {allUploadedImages &&
                  allUploadedImages.map((imgSrc) => (
                    <div className="uploadimage-parent">
                      <div className="overlay">
                        <EyeOutlined
                          className="update-icons cursor-pointer mr-2"
                          onClick={() => onPreview(imgSrc)}
                        />
                        <DeleteOutlined
                          className="update-icons cursor-pointer"
                          onClick={() => removeImage(imgSrc)}
                        />
                      </div>
                      <img
                        src={imgSrc.url}
                        className="uploaded-images mt-2"
                        alt="product"
                      />
                    </div>
                  ))}
                <Upload
                  className="mt-2"
                  multiple
                  customRequest={dummyRequest}
                  // action={"/"}
                  listType="picture-card"
                  name="files"
                  beforeUpload={handleImageUpload}
                  // onPreview={onPreview}
                  disabled={isUploading || isLoading}
                >
                  {!isUploading ? (
                    allUploadedImages?.length < 10 && "+ Upload"
                  ) : (
                    <Loader1 />
                  )}
                </Upload>
              </div>
              <Row gutter={8} className="w-100 d-flex justify-content-end">
                <Col className="mt-2 mr-2">
                  <Button
                    className="submit-btn-solid"
                    // onClick={() => navigate(PATH.ADMIN_ALL_PRODUCTS)}
                    onClick={() => setOpen(false)}
                    disabled={isUploading || isLoading || isUpdating}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="mt-2 submit-btn-solid"
                      htmlType="submit"
                      disabled={isUploading || isLoading || isUpdating}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <Loader1 />
          )}
        </div>
      </Card>
    </Modal>
  );
}

export default UpdateProductModal;
UpdateProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
