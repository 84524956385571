/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OrderServices from "./Order.services";

const initialState = {
  createOrder: [],
  getOrderById: [],
  getAllOrders: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  isUploading: false,
  message: "",
};

export const createOrderAction = createAsyncThunk(
  "Order/createOrderAction",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      debugger;
      const response = await OrderServices.createOrder(finalData);
      if (response) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrderByIdAction = createAsyncThunk(
  "Order/getOrderByIdAction",
  async ({ orderId, Notification, moveToNext }, thunkAPI) => {
    try {
      const response = await OrderServices.getOrderById(orderId);
      if (response) {
        if (Notification) {
          Notification("Product added successfully!", "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notification("failed to add product!", "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllOrdersAction = createAsyncThunk(
  "Order/getAllOrdersAction",
  async (thunkAPI) => {
    try {
      const response = await OrderServices.getAllOrders();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderSlice = createSlice({
  name: "Order",
  initialState,
  reducers: {
    clearOrder: (state) => {
      state.createOrder = [];
      state.getOrderById = [];
      state.getAllOrders = [];
      state.isLoading = false;
      state.isUploading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrderAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrderAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createOrder = action.payload;
      })
      .addCase(createOrderAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOrderByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getOrderById = action.payload;
      })
      .addCase(getOrderByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllOrdersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOrdersAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllOrders = action.payload;
      })
      .addCase(getAllOrdersAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearOrder } = orderSlice.actions;

export default orderSlice.reducer;
